"use strict";
var postForm = function (id) {
    grecaptcha.execute(grecaptchaSiteKey, {action: 'authentication'}).then(function (token) {
        let currentForm = $(id);
        let url = currentForm.attr('action');
        let location = currentForm.data('target');
        let method = currentForm.attr('method');
        let timeout = currentForm.data('timeout');
        let reset = currentForm.data('reset');
        let dataString = decodeURIComponent($(currentForm).serialize());
        dataString += '&g_recaptcha_response=' + token;
        $('.btn-primary').attr('disabled', true);
        $.ajax({
            cache: false,
            type: method,
            dataType: 'json',
            data: dataString,
            url: url,
            error: function (data) {
                $('.message-holder').html(messageAlert('danger',data.responseJSON.errors, data.status)).show();
                $('.btn-primary').attr('disabled', false);
            },
            success: function (data) {
                $('.message-holder').html(messageAlert('success',data.message)).show();
                $('.btn-primary').attr('disabled', false);
                if (method === 'post' || reset === 'true') {
                    $(currentForm)[0].reset();
                }
                if (location != null && timeout != null) {
                    setTimeout(function () {
                        window.location.href = location;
                    }, timeout);
                }
            }
        });
    });
}
// Class Definition
var KTLogin = function() {
    var _login;

    var _showForm = function(form) {
        var cls = 'login-' + form + '-on';
        var form = 'kt_login_' + form + '_form';

        _login.removeClass('login-forgot-on');
        _login.removeClass('login-signin-on');
        _login.removeClass('login-signup-on');

        _login.addClass(cls);

       // KTUtil.animateClass(KTUtil.getById(form), 'animate__animated animate__backInUp');
    }

    var _handleSignInForm = function() {
        $('#signin_submit').on('click', function (e) {
            $( "form#kt_login_signin_form" ).validate({
                debug: false,
                rules: {
                    email: {
                        required: true,
                        email: true,
                        maxlength: 100,
                        minlength: 6,
                    },
                    password: {
                        required: true,
                        maxlength: 255,
                    },
                },
                messages: {
                    email: {
                        required: Lang.get('validation.required'),
                        email: Lang.get('validation.email'),
                        maxlength: Lang.get('validation.max.numeric'),
                        minlength: Lang.get('validation.min.numeric'),
                    },
                    password: {
                        required: Lang.get('validation.required'),
                        maxlength: Lang.get('validation.max.numeric'),
                    },
                },
                errorClass: 'is-invalid',
                highlight: function (element, errorClass, validClass) {
                    var elem = $(element);
                    elem.addClass(errorClass);
                },
                unhighlight: function (element, errorClass, validClass) {
                    var elem = $(element);
                    elem.removeClass(errorClass);
                },
                submitHandler: function (form) {
                    postForm("#kt_login_signin_form");
                    e.preventDefault();

                    return false;
                }
            });
        });

        $('#kt_login_forgot').on('click', function (e) {
            e.preventDefault();
            $('.message-holder').html("").hide();
            $('title').html(Lang.get('auth.forgotPassword') + " | " + Lang.get('general.siteTitle'));
            _showForm('forgot');
        });

        $('#kt_login_signup').on('click', function (e) {
            e.preventDefault();
            $('.message-holder').html("").hide();
            $('title').html(Lang.get('auth.titleRegister') + " | " + Lang.get('general.siteTitle'));
            _showForm('signup');
        });
    }

    var _handleSignUpForm = function(e) {

        $('#kt_login_signup_submit').on('click', function (e) {
            $.validator.addMethod(
                "regex",
                function(value, element, regexp) {
                    var check = false;
                    return this.optional(element) || regexp.test(value);
                },
                Lang.get('validation.regex')
            );
            $( "form#kt_login_signup_form" ).validate({
                debug: false,
                rules: {
                    email: {
                        required: true,
                        email: true,
                        maxlength: 100,
                        minlength: 6,
                    },
                    password: {
                        required: true,
                        maxlength: 255,
                        regex: /^(?=.*[A-Z])(?=.*[-!$£@%^&*()_+|~=`{}\[\]:";'<>?,.\/])(?=.*[0-9])(?=.*[a-z]).{6,}$/,
                    },
                    password_confirmation: {
                        required: true,
                        maxlength: 255,
                        equalTo : "#password"
                    },
                    'gdpr[1]': {
                        required: true,
                    },
                },
                messages: {
                    email: {
                        required: Lang.get('validation.required'),
                        email: Lang.get('validation.email'),
                        maxlength: Lang.get('validation.max.numeric'),
                        minlength: Lang.get('validation.min.numeric'),
                    },
                    password: {
                        required: Lang.get('validation.required'),
                        maxlength: Lang.get('validation.max.numeric'),
                    },
                    password_confirmation: {
                        required: Lang.get('validation.required'),
                        maxlength: Lang.get('validation.max.numeric'),
                        equalTo: Lang.get('validation.confirmed'),
                    },
                    'gdpr[1]': {
                        required: Lang.get('validation.required'),
                    },
                },
                errorClass: 'is-invalid',
                highlight: function (element, errorClass, validClass) {
                    var elem = $(element);
                    elem.addClass(errorClass);
                },
                unhighlight: function (element, errorClass, validClass) {
                    var elem = $(element);
                    elem.removeClass(errorClass);
                },
                submitHandler: function (form) {
                    postForm("#kt_login_signup_form");
                    e.preventDefault();
                    return false;
                }
            });

        });

        // Handle cancel button
        $('#kt_login_signup_cancel').on('click', function (e) {
            e.preventDefault();
            $('.message-holder').html("").hide();
            $('title').html(Lang.get('auth.login') + " | " + Lang.get('general.siteTitle'));
            _showForm('signin');
        });
    }

    var _handleForgotForm = function(e) {

        // Handle submit button
        $('#kt_login_forgot_submit').on('click', function (e) {
            $( "form#kt_login_forgot_form" ).validate({
                debug: false,
                rules: {
                    email: {
                        required: true,
                        email: true,
                        maxlength: 100,
                        minlength: 6,
                    },
                },
                messages: {
                    email: {
                        required: Lang.get('validation.required'),
                        email: Lang.get('validation.email'),
                        maxlength: Lang.get('validation.max.numeric'),
                        minlength: Lang.get('validation.min.numeric'),
                    },
                },
                errorClass: 'is-invalid',
                highlight: function (element, errorClass, validClass) {
                    var elem = $(element);
                    elem.addClass(errorClass);
                },
                unhighlight: function (element, errorClass, validClass) {
                    var elem = $(element);
                    elem.removeClass(errorClass);
                },
                submitHandler: function (form) {
                    postForm("#kt_login_forgot_form");
                    e.preventDefault();

                    return false;
                }
            });
        });

        // Handle cancel button
        $('#kt_login_forgot_cancel').on('click', function (e) {
            e.preventDefault();
            $('.message-holder').html("").hide();
            $('title').html(Lang.get('auth.login') + " | " + Lang.get('general.siteTitle'));
            _showForm('signin');
        });
    }

    var _handleNewPasswordForm = function(e) {

        $('#kt_reset_password_submit').on('click', function (e) {
            $.validator.addMethod(
                "regex",
                function(value, element, regexp) {
                    var check = false;
                    return this.optional(element) || regexp.test(value);
                },
                Lang.get('validation.regex')
            );

            $( "form#kt_reset_password_form" ).validate({
                debug: false,
                rules: {
                    email: {
                        required: true,
                        email: true,
                        maxlength: 100,
                        minlength: 6,
                    },
                    password: {
                        required: true,
                        maxlength: 255,
                        regex: /^(?=.*[A-Z])(?=.*[-!$£@%^&*()_+|~=`{}\[\]:";'<>?,.\/])(?=.*[0-9])(?=.*[a-z]).{6,}$/,
                    },
                    password_confirmation: {
                        required: true,
                        maxlength: 255,
                        equalTo : "#password"
                    },
                    'gdpr[1]': {
                        required: true,
                    },
                },
                messages: {
                    email: {
                        required: Lang.get('validation.required'),
                        email: Lang.get('validation.email'),
                        maxlength: Lang.get('validation.max.numeric'),
                        minlength: Lang.get('validation.min.numeric'),
                    },
                    password: {
                        required: Lang.get('validation.required'),
                        maxlength: Lang.get('validation.max.numeric'),
                    },
                    password_confirmation: {
                        required: Lang.get('validation.required'),
                        maxlength: Lang.get('validation.max.numeric'),
                        equalTo: Lang.get('validation.confirmed'),
                    },
                    'gdpr[1]': {
                        required: Lang.get('validation.required'),
                    },
                },
                errorClass: 'is-invalid',
                highlight: function (element, errorClass, validClass) {
                    var elem = $(element);
                    elem.addClass(errorClass);
                },
                unhighlight: function (element, errorClass, validClass) {
                    var elem = $(element);
                    elem.removeClass(errorClass);
                },
                submitHandler: function (form) {
                    postForm("#kt_reset_password_form");
                    e.preventDefault();

                    return false;
                }
            });
        });

        // Handle cancel button
        $('#kt_login_forgot_cancel').on('click', function (e) {
            e.preventDefault();

            _showForm('signin');
        });
    }

    // Public Functions
    return {
        // public functions
        init: function() {
            _login = $('#kt_login');

            _handleSignInForm();
            _handleSignUpForm();
            _handleForgotForm();
            _handleNewPasswordForm();
        }
    };
}();

// Class Initialization
jQuery(document).ready(function() {
    KTLogin.init();
});
