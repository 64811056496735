messageAlert = (function(msgType, msgArr, status = null) {
    let msg = '';
    if (status != null && typeof (msgArr) === 'undefined') {
        switch (status) {
            case 419:
                msg += '<div>'+Lang.get('auth.tokenError')+'</div>';
                break;
            case 404:
                msg += '<div>'+Lang.get('auth.notFound')+'</div>';
                break;
            default:
                msg += '<div>'+Lang.get('auth.unknownError')+'</div>';
                break;
        }
    } else {
        $.each(msgArr, function (key, value) {
            msg += '<div>' + value + '</div>';
        });
    }
    let fullMsg = '<div class="alert alert-' + msgType + ' alert-dismissible" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + msg +
        '</div>';
    return fullMsg;
});
