"use strict";

//** Begin: Global mandatory plugins
window.jQuery = window.$ = require("jquery");
require("bootstrap");

window.FormValidation = require("jquery-validation");
require("jquery-validation/dist/additional-methods.js");
require("../common/jquery-validation/jquery-validation.init");

window.Lang = require("lang.js/dist/lang.min");
require('../common/lang.js/lang.dist');

require("../common/message-alert/message-alert");

//** Begin: Custom scripts
require('../public/auth/auth');